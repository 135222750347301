var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showBroadcastMsg
    ? _c("div", { staticClass: "card" }, [
        _c(
          "div",
          { staticClass: "card-body pb-1" },
          [
            _c(
              "button",
              {
                staticClass: "close text-primary",
                attrs: { type: "button" },
                on: { click: _vm.dismissBroadcast },
              },
              [_c("i", { staticClass: "fa-solid fa-xmark fa-xs" })]
            ),
            _vm._v(" "),
            _c("markdown-preview", { attrs: { input: _vm.message } }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }