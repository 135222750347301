var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dropdown",
      class: { show: _vm.dropdownActive },
      attrs: { id: "dropdown-" + _vm.id },
    },
    [
      _c(
        "div",
        {
          staticClass: "input-group input-group-sm responsive-width",
          on: { click: _vm.showDropdown },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.query,
                expression: "query",
              },
            ],
            ref: "input",
            staticClass: "form-control custom-input",
            attrs: { placeholder: _vm.$t("Quick search") },
            domProps: { value: _vm.query },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.query = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.query
            ? _c("div", { staticClass: "input-group-append" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-sm clear-btn input-group-append-content",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.clearQuery.apply(null, arguments)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa-solid fa-xmark" })]
                ),
              ])
            : _c("div", { staticClass: "input-group-append" }, [_vm._m(0)]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dropdown-menu responsive-width p-0 mt-2",
          class: { "d-block": _vm.dropdownActive },
        },
        [
          _vm.initialized
            ? _c(
                "div",
                [
                  _vm.items.length === 0
                    ? _c("div", { staticClass: "px-2 my-1" }, [
                        _c("strong", { staticClass: "text-muted font-sm" }, [
                          _vm._v(_vm._s(_vm.$t("No results."))),
                        ]),
                      ])
                    : _vm._l(_vm.items, function (item, index) {
                        return _c("div", { key: item.endpoint }, [
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item p-2",
                              attrs: { href: item.endpoint },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-between",
                                },
                                [
                                  _c("strong", { staticClass: "font-sm" }, [
                                    _vm._v(_vm._s(item.identifier)),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "badge badge-light border border-muted font-weight-normal ml-3",
                                      },
                                      [_vm._v(_vm._s(item.type))]
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "small",
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("Last modified")) +
                                      " "
                                  ),
                                  _c("from-now", {
                                    attrs: { timestamp: item.timestamp },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          index < _vm.items.length - 1
                            ? _c("div", { staticClass: "dropdown-divider m-0" })
                            : _vm._e(),
                        ])
                      }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.initialized
            ? _c("i", {
                staticClass: "fa-solid fa-circle-notch fa-spin text-muted p-2",
              })
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "search-btn input-group-text input-group-append-content" },
      [_c("i", { staticClass: "fa-solid fa-magnifying-glass fa-sm" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }