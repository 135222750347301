var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "toast",
      staticClass: "toast mw-100",
      attrs: {
        "data-animation": "false",
        "data-autohide": "false",
        "data-delay": "0",
      },
    },
    [
      _c("div", { staticClass: "toast-header bg-primary" }, [
        _c("span", { staticClass: "mr-auto" }, [
          _vm._v(_vm._s(_vm.notification.data.title)),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "ml-2 close text-white",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.dismiss(_vm.notification)
              },
            },
          },
          [_c("i", { staticClass: "fa-solid fa-xmark fa-xs" })]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "toast-body py-2" }, [
        _c("div", {
          staticClass: "wb-break-all",
          domProps: { innerHTML: _vm._s(_vm.notification.data.body) },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "mt-1" }, [
          _c(
            "small",
            { staticClass: "text-muted" },
            [
              _c("from-now", {
                attrs: { timestamp: _vm.notification.created_at },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }